import AirtableTable from "../AirtableTable"

export class FiresideChatsTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Fireside Chats")

    this.fields = [
      "Name",
      "Broad-based Technology Area",
	  "Category Text",
      "Indigenous Community",
	  "P/T Name",
      "Subtitle",
	  "Bio",
	  'Illustration File',
	  "Date Web URL Updated",
	  "Video Link",
      "Web URL"
    ]
  }

  getAll = () => {
    return this.list({
      sort: [
        {
          field: "Name",
          direction: "asc"
        }
      ]
    })
  }

  getAllCompleted = () => {
	return this.list({
	  filterByFormula: `{Interview Status} = "Completed"`,
	  sort: [
		{
		  field: "Name",
		  direction: "asc"
		}
	  ]
	})
  }

  getByBBTA = bbta => {
    let condition = {
      filterByFormula: `{Broad-based Technology Area} = "${bbta}"`,
      sort: [
        {
          field: "Name",
          direction: "asc"
        }
      ]
    }

    return this.list(condition)
  }

  getByName = name => {
	let query = '';
	if (Array.isArray(name)) {
		query = 'AND(';
		name.forEach((n) => {
			query += `FIND("${n}", LOWER({Name})) > 0, `
		})
		query = query.slice(0, -2);
		query += ')';
	} else {
		query = `FIND("${name}", LOWER({Name})) > 0`
	}

	let condition = {
	  filterByFormula: query,
	  sort: [
		{
		  field: "Name",
		  direction: "asc"
		}
	  ]
	}

	return this.list(condition)
  }
}