import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import FiresideChats from './pages/firesideChats';
import Video from './pages/firesideChats/video';
import About from './pages/about';
import './assets/css/future-pathways.css';

function App() {
  return (
    <Router>
      <Routes>
		<Route path="/" element={<Layout />}>
		  <Route index element={<FiresideChats />} />
		  <Route path="about" element={<About />} />
		</Route>
		<Route path="video/" element={<Layout />}>
			<Route path=":slug" element={<Video />} />
		</Route>
	  </Routes>
    </Router>
  );
}

export default App;
