import { useEffect, useState } from "react"
import airtable from "../airtables"
// import { useDispatch } from "react-redux"

export default function useFiresideChats() {
  const [firesideChats, setFiresideChats] = useState([])
//   const dispatch = useDispatch()
  
  useEffect(() => {
	airtable.firesideChats.getAllCompleted().then(res => {
		setFiresideChats(res)
	})
  }, [])

  return { firesideChats }
}