export const getNiceFormatDateString = (date, locale = "en") => {
	if (!date) return ""
  
	if (typeof date === "string") {
	  date = new Date(date)
	}
  
	return new Intl.DateTimeFormat(locale, {
	  month: "long",
	  day: "numeric",
	  year: "numeric"
	}).format(date)
  }
  
  export const getNiceFormatDateTimeString = (date, locale = "en") => {
	if (!date) return ""
  
	if (typeof date === "string") {
	  date = new Date(date)
	}
  
	return Intl.DateTimeFormat(locale, {
	  year: "numeric",
	  month: "long",
	  day: "numeric",
	  hour: "numeric",
	  minute: "2-digit"
	}).format(date)
  }