export default function Footer() {
	return (
		<div className="section-footer">
			<div className="container">
				<div className="footer-3-grid">
					<div className="footer-column">
						<div className="div-block-5">
							<a href="https://www.tigweb.org/" target="_blank" rel="noopener noreferrer" className="footer-logo w-nav-brand"><img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/6045f6a613581ddae9f47c97_takingitglobal.png" width="100" sizes="(max-width: 767px) 120px, 140px" srcSet="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/6045f6a613581ddae9f47c97_takingitglobal-p-500.png 500w, https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/6045f6a613581ddae9f47c97_takingitglobal.png 520w" alt="" className="logo" /></a>
							<a href="https://www.rbc.com/dms/enterprise/futurelaunch/" target="_blank" rel="noopener noreferrer" className="footer-logo-2 w-nav-brand"><img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/6045f7892205df30be2fdeb3_rbc.png" width="100" alt="" className="logo" /></a>
						</div>
						<p className="footer-description">
							Future Pathways Fireside Chats are a project of TakingITGlobal's Connected North Program.
							<br /><br />
							Funding is generously provided by the RBC Foundation in support of RBC Future Launch, and the Government of Canada's Supports for Student Learning program.
						</p>
					</div>
					<div className="footer-column newsletter">
						<h4 className="footer-section-title">Sign up to be notified of new Fireside Chats and program updates!</h4>
						<div className="w-form">
							<form id="newsletterForm" name="email-form" data-name="Email Form" method="get" className="grid-inline-email-form" data-wf-page-id="60e82e79a1f5c93c6e1d0e3f" data-wf-element-id="121fa32e-0a3c-24f1-787b-010b9e79ecb6" aria-label="Email Form">
								<input className="input w-input" maxLength="256" name="email-2" data-name="Email 2" placeholder="Email Address" type="email" id="newsletterEmail" required="" />
								<button id="newsletterSubmit" type="submit" className="button-submit w-button">Submit</button>
							</form>
							<div id="newsletterSuccessField" className="empty-state inline w-form-done" tabIndex="-1" role="region" aria-label="Email Form success">
								<div>Thank you! Your submission has been received!</div>
							</div>
							<div id="newsletterErrorField" className="error-message inline w-form-fail" tabIndex="-1" role="region" aria-label="Email Form failure">
								<div>Oops! Please enter a valid e-mail address.</div>
							</div>
						</div>
					</div>
					<div className="footer-column right">
						<a href="https://www.firesidechats.ca/" className="margin-bottom-extra-small-2">Home</a>
						<a href="https://www.firesidechats.ca/careers" aria-current="page" className="margin-bottom-extra-small-3 w--current">Careers</a>
						<a href="https://www.firesidechats.ca/videos" className="margin-bottom-extra-small-5">Fireside&nbsp;Chats</a>
						<a href="https://www.firesidechats.ca/about" className="margin-bottom-extra-small-5">About</a>
					</div>
				</div>
				<div className="footer-bottom-row">
					<div className="footer-social-links-wrapper">
						<a href="https://twitter.com/aconnectednorth" target="_blank" rel="noopener noreferrer" className="footer-social-icon w-inline-block">
							<img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/604475e337463b22fbfde970_twitter.svg" alt="" />
						</a>
						<a href="https://www.instagram.com/fp.firesidechats/" target="_blank" rel="noopener noreferrer" className="footer-social-icon w-inline-block">
							<img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/604475ed31ca2e377314c200_instagram.svg" alt="" />
						</a>
						<a href="https://www.facebook.com/takingitglobal/" target="_blank" rel="noopener noreferrer" className="footer-social-icon w-inline-block">
							<img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/604475f5ae96dae78ef9031f_facebook.svg" alt="" />
						</a>
					</div>
					<a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank" rel="noopener noreferrer" className="link-block-3 w-inline-block">
						<img src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/628a6dad17f8bb09724582aa_by-nc-nd.png" loading="lazy" data-w-id="fc292b15-6141-7eda-697f-b5f98d246880" alt="" className="image-2" />
					</a>
				</div>
			</div>
		</div>
	)
}