export default function AboutPage() {
  return (
	<div className="section-2">
		<div className="container-2 w-container">
			<h1>About Fireside Chats</h1>
			<div>
				<strong>Indigenous Role Models: Mentoring Youth for a Brighter Future‍</strong>
				<br />‍<br />
				Are you or is somebody you know a student or a young person, eager to prepare for the future? Do you want to learn from successful First Nations, Metis, and Inuit role models who have made a difference in their communities? If yes, then Future Pathways: Fireside Chats is just what you need! We offer discussion-based video interviews with inspiring First Nations, Metis, and Inuit role models who share their knowledge and experience with youth.
				<br /><br />
				This resource is designed to help young people discover their potential and learn from the experiences of Indigenous role models. Through Future Pathways: Fireside Chats, you can gain valuable insights into various career paths as well as personal life paths. Our mentors are successful Indigenous professionals who have made a difference in their respective fields and they are eager to share their knowledge with you.
				<br /><br />
				<strong>Why Choose Us?</strong>
				<br /><br />
				We believe that every young person has the potential to succeed. We provide a safe and supportive space for youth to learn from Indigenous educators and role models who have overcome challenges and achieved success. Our program is tailored to meet the needs of students and young people who are eager to learn, grow, and succeed in life.For educators, Future Pathways: Fireside Chats has discussion guides, lesson plans, blog posts, and a teacher guide to assist in many different ways.
				<br /><br />
				‍<strong>Our Interviewees‍</strong>
				<br /><br />
				Our mentors are successful Indigenous professionals who have made a difference in their own and other’s communities. They come from various backgrounds and fields of expertise, including business, education, health, and arts. Our mentors are passionate about sharing knowledge and their experience. Through telling their personal stories, Fireside Chats interviewees offer guidance to help young people achieve their goals.
				<br /><br />
				We are looking for Indigenous Role Models to share their knowledge with youth who are eager to prepare for their future in a filmed discussion-based interview. Our target audience for the Future Pathways: Fireside Chats is youth within the Connected North network, which currently reaches over 150 schools located across northern and remote communities in Canada. These videos are released on social media platforms but will also be kept as a resource for classrooms to utilize in the future.&nbsp;As a contributing interviewee, TakingITGlobal is able to provide an honorarium of $250. We are excited at the prospect of expanding our content with a focus on highlighting your journey and further connecting students to positive, inspiring mentors!<br /><br />
				‍<strong>How It Works‍</strong>
				<br /><br />
				Our program is always open to interviewing more professionals that want to make a difference in young Indigenous, Metis and Inuit lives. We are constantly updating our site with more interviewees sharing their stories, so each time you return there is new relevant content to learn from.
				<br /><br />
				Future Pathways: Fireside Chats is available in a variety of formats. Whether you enjoy reading blogs, watching interview videos, or listening to us on your favourite podcast platform, Future Pathways: Fireside Chats can inspire you in any way you like!
				<br /><br />
				If you are a student or a young person eager to prepare for your future, Future Pathways: Fireside Chats is just what you need. We offer a unique opportunity to learn from successful Indigenous, Metis, and Inuit role models who have made a difference in their communities. Through Future Pathways: Fireside Chats, you can gain valuable insights into various career paths and learn how to overcome challenges in life. Start watching today and discover your potential!
			</div>
		</div>
	</div>
  );
}