import logoImg from '../../assets/images/future-pathways-logo.png';

export default function Header() {
	return (
		<div className="header w-nav">
			<div className="header-container">
				<a href="https://www.firesidechats.ca/" className="brand w-nav-brand">
					<img src={logoImg} alt="Future Pathways" className="image" />
				</a>
				<nav role="navigation" className="nav-menu w-nav-menu">
					<a href="https://www.firesidechats.ca/careers" className="nav-link w-nav-link">Careers</a>
					<a href="https://www.firesidechats.ca/videos" className="nav-link w-nav-link">Explore</a>
					<a href="https://www.firesidechats.ca/lesson-plans" className="nav-link w-nav-link">Lesson Plans</a>
					<a href="https://www.firesidechats.ca/about" className="nav-link w-nav-link">About</a>
				</nav>
				<div className="menu-button w-nav-button">
					<div className="icon w-icon-nav-menu"></div>
				</div>
			</div>
		</div>
	)
}