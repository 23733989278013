import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNiceFormatDateString } from "../../helpers/time";
import airtable from "../../lib/airtables";

const STYLES = { videoIframeContainer: { padding: '56.25% 0 0 0', position: 'relative', }, videoIframe: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' } }

const getVimeoId = (url) => {
	const parts = url.split('/');
	return parts[parts.length - 1];
}

const VideoSection = (chat) => {
	const videoId = getVimeoId(chat['Video Link']);
	const identity = chat['Indigenous Community'] ? chat['Indigenous Community'].join(', ') : '';

	return (
		<>
			<div className="section">
				<div className="container">
					<div className="video-wrapper-large">
						<div className="w-video w-embed" style={{paddingTop:"56.27659574468085%"}}>
							<iframe
								title="vimeo-player"
								src={`https://player.vimeo.com/video/${videoId}`}
								width="940"
								height="529"
								allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
								allowFullScreen
								style={STYLES.videoIframe}
							>
							</iframe>
						</div>
					</div>
				</div>
			</div>
			<div className="section">
				<div className="container">
					<div className="grid-sidebar">
						<div>
							<div className="content-intro">
								<h1 className="heading-lg">{chat.Name}</h1>
								<p className="text-large">{chat.Subtitle}</p>
							</div>
						{chat.Bio && (
							<div data-duration-in="300" data-duration-out="100" data-current="Video Notes" data-easing="ease" className="w-tabs">
								<div className="video-tab-menu w-tab-menu" role="tablist">
									<a data-w-tab="Video Notes" className="video-tab-link w-inline-block w-tab-link w--current" id="w-tabs-0-data-w-tab-0" href="#w-tabs-0-data-w-pane-0" role="tab" aria-controls="w-tabs-0-data-w-pane-0" aria-selected="true"><div>Background</div></a>
									<a data-w-tab="Transcript" className="video-tab-link w-inline-block w-condition-invisible w-tab-link" tabIndex="-1" id="w-tabs-0-data-w-tab-1" href="#w-tabs-0-data-w-pane-1" role="tab" aria-controls="w-tabs-0-data-w-pane-1" aria-selected="false"><div>Transcript</div></a>
								</div>
								<div className="w-tab-content">
									<div data-w-tab="Video Notes" className="w-tab-pane w--tab-active" id="w-tabs-0-data-w-pane-0" role="tabpanel" aria-labelledby="w-tabs-0-data-w-tab-0">
										<div className="video-notes w-richtext">
											{chat.Bio}
										</div>
									</div>
									<div data-w-tab="Transcript" className="w-tab-pane" id="w-tabs-0-data-w-pane-1" role="tabpanel" aria-labelledby="w-tabs-0-data-w-tab-1">
										<div className="video-transcript w-richtext">
											<ul>
												<li><strong>0:00</strong> - Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
												<li><strong>1:11</strong> - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
												<li><strong>2:22</strong> - Lorem ipsum dolor sit amet</li>
												<li><strong>3:33</strong> - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						)}
						</div>
						<div>
							<div className="sticky">
								<div className="card-wrapper">
									<h1 className="card-wrapper-title">Key Parts</h1>
									<ul className="card-list w-list-unstyled">
										<li className="card-list-item-border">
											<div className="row-space-between">
												<div><strong>Career</strong></div>
											</div>
											<div className="div-block-4">
												<span className="link-2">{chat['Category Text']}</span>
											</div>
										</li>
										<li className="card-list-item-border">
											<div className="row-space-between">
												<div><strong>Identity</strong></div>
											</div>
											<div className="text-block-3">
												{identity}
											</div>
										</li>
										<li className="card-list-item-border">
											<div className="row-space-between">
												<div style={{marginRight: '10px'}}><strong>Province/Territory</strong></div>
											</div>
											<div className="text-block-5">
												{chat['P/T Name']}
											</div>
										</li>
										<li className="card-list-item-border">
											<div className="row-space-between">
												<div><strong>Date</strong></div>
											</div>
											<div className="text-block-3">
												{getNiceFormatDateString(new Date(chat['Date Web URL Updated']))}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default function Video() {
	const { slug } = useParams();
	const parts = slug.split("-");

	const [chat, setChat] = useState();

	useEffect(() => {
		const fetchChat = async () => {
			const data = await airtable.firesideChats.getByName(parts);
			setChat(data[0]);
		};

		fetchChat();
	}, [parts]);

	return (
		<>
			{chat && (
				<VideoSection {...chat} />
			)}
		</>
	);
}