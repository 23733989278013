import AirtableBase from "./AirtableBase"
import { FiresideChatsTable } from "./tables/FiresideChats"

export class Airtable extends AirtableBase {
  constructor(apiKey, base) {
    super(apiKey, base)

    this.firesideChats = new FiresideChatsTable(this.instance)
  }
}

const AIRTABLE_APP = process.env.REACT_APP_AIRTABLE_BASE_ID

const AIRTABLE_KEY = process.env.REACT_APP_AIRTABLE_API_KEY

const airtableInstance = new Airtable(AIRTABLE_KEY, AIRTABLE_APP)

export default airtableInstance
