import React, { useState, useCallback } from "react";
// import useFiresideChats from './lib/hooks/use-firesideChats';
import { BroadBasedTechnologyAreas } from "../../helpers/constants";
import { Link } from "react-router-dom";
import useFiresideChats from "../../lib/hooks/use-firesideChats";
import heroImage from "../../assets/images/future-pathways-hero-800.png";
import '../../assets/css/accordion.css';

const AccordionItem = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = useCallback(() => {
		setIsOpen(prevIsOpen => !prevIsOpen);
	}, []);

	return (
		<div className="accordion-item" onClick={handleToggle}>
			<div className="accordion-title">
				{title}
				{isOpen ? <span>&#9660;</span> : <span>&#9650;</span>}
			</div>
			{isOpen && <div className="accordion-content">{children}</div>}
		</div>
	);
};

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export default function FiresideChatsPage() {
	const { firesideChats } = useFiresideChats();

	const getFireSideChats = useCallback(bbta => {
		return firesideChats
			.filter(chat => 
				chat['Broad-based Technology Area'] && 
				chat['Broad-based Technology Area'].includes(bbta) &&
				chat['Illustration File'] // Only include chats with an Illustration File
			)
	}, [firesideChats]);

	return (
		<>
			<div className="section">
				<div className="container homehero">
					<div className="div-block-19">
						<div className="section-hero-copy">
							<div className="text-block-11">Broad-based Technology Subject Areas</div>
							<h1 className="heading-7">Indigenous Role Models</h1>
							<div className="left-aligned">Explore these video interviews with inspiring First Nations, Metis, and Inuit role models who share their knowledge and experience with youth, aligned to the Broad-based Technology Subject Areas of the Ontario curriculum.</div>
							<a href="https://www.dcp.edu.gov.on.ca/en/bbt-subject-areas" target="_blank" rel="noopener noreferrer" className="button w-button">Learn more about Broad-based Technology Subject Areas</a>
						</div>
						<img src={heroImage} className="image-4" alt="Indigenous Role Models" />
					</div>
				</div>
			</div>
			<div className="section">
				<div className="container">
					{BroadBasedTechnologyAreas.map((bbta, index) => {
						const chatsForArea = getFireSideChats(bbta);
						if (chatsForArea.length === 0) return null; // Don't render accordion if no chats

						return (
							<AccordionItem key={'accordion-' + index} title={bbta}>
								<div className="w-dyn-items w-row" role="list">
									{chatsForArea.map((chat, index) => {
										return (
											<div key={'card-' + index} className="w-dyn-item w-col w-col-4" role="listitem" style={{marginBottom: "1em"}}>
												<div className="card card-centered">
													<Link to={`https://www.firesidechats.ca/video/${slugify(chat.Name)}`} target="_blank" rel="noopener" className="w-inline-block">
														<img src={chat['Illustration File'][0].thumbnails.large.url} className="image-5" alt="Illustration of Role Model" style={{maxHeight: "238px"}} loading="lazy" />
													</Link>
													<div className="div-block-18">
														<div className="text-block-12 borderright">{chat['Indigenous Community'].join(' and ')}</div>
														<div className="text-block-12">{chat['P/T Name']}</div>
													</div>
													<h4 className="heading-3">{chat.Name}</h4>
													<div className="text-block-30">{chat.Subtitle}</div>
													<div className="category-v2-corner">
														<div className="text-block-31">{chat['Category Text']}</div>
													</div>
													<Link className="button dynamic-text-copy w-inline-block" to={`https://www.firesidechats.ca/video/${slugify(chat.Name)}`} target="_blank" rel="noopener">
														<div className="text-block-33">View</div>
														<div className="text-block-34">{chat.Name}</div>
														<div className="text-block-35">'s Story</div>
													</Link>
												</div>
											</div>
										)
									})}
								</div>
							</AccordionItem>
						);
					})}
				</div>
			</div>
		</>
	);
}