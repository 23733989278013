export const BroadBasedTechnologyAreas = [
	"Communications Technology",
	"Computer Technology",
	"Construction Technology",
	"Green Industries",
	"Hairstyling and Aesthetics",
	"Health Care",
	"Hospitality and Tourism",
	"Manufacturing Technology",
	"Technological Design",
	"Transportation Technology"
]